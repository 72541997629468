import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useResetAnimations } from '../../useResetAnimations';
import './AboutUs.css';

import Header from '../../components/common/Header/Header';
import PageDescription from '../../components/common/PageDescription/PageDescription';
import CallToAction from '../../components/common/CallToAction/CallToAction';
import Footer from '../../components/common/Footer/Footer';
import FounderCard from '../../components/FounderCard/FounderCard';
import Playground from '../../components/Playground/Playground';

import multiLines from '../../assets/images/multi-lines.svg';
import abstractY from '../../assets/images/abstract-y.webp';

import davidCompain from '../../assets/images/david-compain.webp';
import laurentChazallon from '../../assets/images/laurent-chazallon.webp';

/**
 * About us page.
 * @returns {React.Component} About us page.
 */
function AboutUs() {
    // Get the current location
    const location = useLocation();

    // Reference to the elements for animation reset
    const elementsRef = useResetAnimations();

    // Reference to the previous hash and key values
    const prevHashRef = useRef(location.hash);
    const prevKeyRef = useRef(location.key);

    // Scroll to top when the location changes
    useEffect(() => {
        // Scroll instantly to the top if the location changed, otherwise scroll smoothly
        if (location.hash === prevHashRef.current && location.key === prevKeyRef.current) {
            window.scrollTo({ top: 0 });
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        // Update the previous hash and key values
        prevHashRef.current = location.hash;
        prevKeyRef.current = location.key;
    }, [location.hash, location.key]);

    return (
        <div ref={elementsRef} className="about-us">
            <Helmet>
                <title>Clayver - About us</title>
                <meta name="description" content="Clayver is a technology-driven consulting firm, empowering our clients to address business challenges through tech automation and data-driven decisions. Clayver is headquartered in Geneva, but we follow our Clients wherever they go." />
            </Helmet>
            <Header position={"sticky"} />
            <PageDescription
                title="> About Clayver"
                description="Clayver is a technology-driven consulting firm, empowering our clients to address business challenges through tech automation and data-driven decisions. Clayver is headquartered in Geneva, but we follow our Clients wherever they go."
                illustration={multiLines} />
            <section className='founders__section'>
                <FounderCard image={davidCompain} name="David Compain" description="Strategy & operations expert, leveraging tech. for processes & business automation" link="https://www.linkedin.com/in/davidcompain/" background="var(--color-light-gray-1)" reverse />
                <FounderCard image={laurentChazallon} name="Laurent Chazallon" description="Problem solver, strong experience in robust IT architecture, critical for business scaling" link="https://www.linkedin.com/in/laurent-chazallon/" background="var(--color-light-gray-0)" />
                <div className="founders__content">
                    <h2 className='founders__title animated'>The founders</h2>
                    <p className='founders__description animated'>Founded in 2023 by 2 complementary partners, we are a team of 12 local & offshore seasoned professionals, with expertise in SaaS-Based Infrastructure, Process Optimization and Business Intelligence.</p>
                </div>
            </section>
            <section className="about__section">
                <div className="about__text">
                    <h2 className='about__title animated'>What sets us apart</h2>
                    <p className='animated'>3 pillars focusing on agile delivery:</p>
                    <ul className="about__pilars animated">
                        <li>
                            <p className='highlights'>Our services</p>
                            <p>Efficient digital environment is now a critical competitive advantage. We provide you with the most relevant services to use your data and digital tools at their full potential.</p>
                        </li>
                        <li>
                            <p className='highlights'>Our metholodogy</p>
                            <p>Beyond mere slide production. We roll out a robust project-methodology, with well-defined milestones and regular deliverables.</p>
                        </li>
                        <li>
                            <p className='highlights'>Our team</p>
                            <p>High staff turnover leads to reduced expertise, poor commitment and engagement. Our unique talent attraction and retention model, a core part of our DNA, revolutionises client outcomes.</p>
                        </li>
                    </ul>
                </div>
                <div className="about__image">
                    <img src={abstractY} alt="Abstract rays" />
                </div>
            </section>
            <Playground />
            <CallToAction />
            <Footer />
        </div>
    );
}

export default AboutUs;
