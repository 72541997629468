import React from 'react';
import './Playground.css';

import mongoDBLogo from '../../assets/images/mongo-db.svg';
import chatGPTLogo from '../../assets/images/chatgpt.svg';
import jiraLogo from '../../assets/images/jira.svg';
import powerBILogo from '../../assets/images/power-bi.svg';
import googleCloudLogo from '../../assets/images/google-cloud.svg';
import exoscaleLogo from '../../assets/images/exoscale.svg';
import salesforceLogo from '../../assets/images/salesforce.svg';
import zapierLogo from '../../assets/images/zapier.svg';
import notionLogo from '../../assets/images/notion.svg';
import dockerLogo from '../../assets/images/docker.svg';

/**
 * Playground component showcasing the technologies Clayver uses.
 * @returns {React.Component} Playground component.
 */
const Playground = () => {
    const logos = [
        mongoDBLogo,
        chatGPTLogo,
        jiraLogo,
        powerBILogo,
        googleCloudLogo,
        exoscaleLogo,
        salesforceLogo,
        zapierLogo,
        notionLogo,
        dockerLogo,
    ];

    return (
        <section className="playground">
            <div className="playground__text">
                <p>Our Playground</p>
            </div>
            <div className="playground__logos">
                {logos.map((logo, index) => (
                    <img key={index} src={logo} alt="Logo" />
                ))}
                {logos.map((logo, index) => (
                    <img key={index + logos.length} src={logo} alt="Logo" />
                ))}
            </div>
        </section>
    );
};

export default Playground;
