import React, { useState } from 'react';
import axios from 'axios';
import './ContactForm.css';

import Button from '../common/Button/Button';

/**
 * Contact form component.
 * @returns {React.Component} ContactForm component.
 */
const ContactForm = () => {
    // State to store form data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    // State to store form submission status and feedback text color
    const [formStatus, setFormStatus] = useState(null);
    const [formStatusColor, setFormStatusColor] = useState('var(--color-light-green)');

    // Function to handle input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormStatus(null);

        // Send form data to the server using axios
        axios.post('https://formspree.io/f/mgegvbjr', formData)
            .then(response => {
                console.log('Form submitted successfully');
                setFormStatus('Thank you for reaching out! We will get back to you soon.');
                setFormStatusColor('var(--color-light-green)');
            })
            .catch(error => {
                console.log('Error submitting form', error);
                setFormStatus('Please fill in all the required fields correctly.');
                setFormStatusColor('red');
            });
    };

    return (
        <form className='contact-form'>
            <input type="text" name="name" placeholder="Name*" value={formData.name} onChange={handleChange} />
            <input type="email" name="email" placeholder="Email*" value={formData.email} onChange={handleChange} />
            <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange} />
            <Button primary darkMode onClick={handleSubmit}>Send</Button>
            {formStatus && <p style={{ color: formStatusColor }}>{formStatus}</p>}
        </form>
    );
};

export default ContactForm;