import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useResetAnimations } from '../../useResetAnimations';
import './Contact.css';

import Header from '../../components/common/Header/Header';
import PageDescription from '../../components/common/PageDescription/PageDescription';
import Footer from '../../components/common/Footer/Footer';
import ContactForm from '../../components/ContactForm/ContactForm';

import multiSquares from '../../assets/images/multi-squares.svg';

/**
 * Contact page.
 * @returns {React.Component} Contact page.
 */
function Contact() {
    const location = useLocation();
    const elementsRef = useResetAnimations();

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [location.hash, location.key]);

    return (
        <div ref={elementsRef} className="contact">
            <Helmet>
                <title>Clayver - Contact</title>
                <meta name="description" content="Embark on your digital journey with Clayver. Contact us to share your project and explore tailored automation solutions. Let's innovate together!" />
            </Helmet>
            <Header position={"sticky"} isContact />
            <PageDescription
                title="> Reach out to us!"
                description="Embark on your digital journey with Clayver. Contact us to share your project and explore tailored automation solutions.
                Let's innovate together!"
                illustration={multiSquares} />
            <section className="contact-info">
                <div className="contact-form__container animated">
                    <h2>Start your automation journey with Clayver.</h2>
                    <ContactForm />
                </div>
                <div className="contact-info__content animated">
                    <p className='highlights'>Email</p>
                    <a href="mailto:yourproject@clayver.ch">yourproject@clayver.ch</a>
                    <p className='highlights'>Office</p>
                    <p>Clayver SA</p>
                    <p>Cours des Bastions 3Bis</p>
                    <p>1205 Geneva</p>
                    <p>Switzerland</p>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Contact;
