import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook that resets animations for elements when they become visible in the viewport.
 * @returns {React.RefObject} A ref object that holds a reference to the animated elements.
 */
export function useResetAnimations() {
    const animatedElementsRef = useRef(null);
    const location = useLocation();
  
    useEffect(() => {
      if (animatedElementsRef.current) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('fadeInUp');
              observer.unobserve(entry.target);
            }
          });
        }, {
          // Start observing 100px before the element enters the viewport to avoid blinking animations
          rootMargin: '50px 0px'
        });
  
        const animatedElements = animatedElementsRef.current.querySelectorAll('.animated');
        animatedElements.forEach(element => {
          observer.observe(element);
        });
  
        return () => {
          animatedElements.forEach(element => {
            observer.unobserve(element);
            element.classList.remove('fadeInUp');
          });
        };
      }
    }, [location]);
  
    return animatedElementsRef;
  }