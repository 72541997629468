import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import logo from '../../../assets/images/logo.svg';
import linkedinIcon from '../../../assets/images/linkedin.svg';

/**
 * Footer component.
 * @returns {React.Component} Footer component.
 */
const Footer = () => {
    return (
        <footer className="footer">
            <div className='footer__container'>
                <Link className="footer__logo" to="/">
                    <img className="footer__logo" src={logo} alt="Clayver Logo" />
                </Link>
                <div className="footer__links">
                    <Link to="/">Home</Link>
                    <Link to="/#services">Our services</Link>
                    <Link to="/about-us">About us</Link>
                    <a href="https://www.linkedin.com/company/clayver-ch/" target="_blank" rel="noopener noreferrer" className="footer__linkedin">
                        <img src={linkedinIcon} alt="LinkedIn" className="footer__linkedin-icon" />
                    </a>
                </div>
            </div>
            <hr className="footer__divider" />
            <p>
                &copy; 2024 Clayver
            </p>
        </footer>
    );
}

export default Footer;
