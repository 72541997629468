import React from 'react';
import styles from './Button.module.css';
import { Link } from 'react-router-dom';

/**
 * Button component.
 * @param {Object} props - Component props.
 * @param {boolean} props.primary - Whether the button is primary or secondary.
 * @param {boolean} props.animated - Whether the button is animated or not.
 * @param {boolean} props.darkMode - Whether the button is in dark mode or not.
 * @param {string} props.link - Link to which the button should redirect.
 * @param {function} props.onClick - Function to be executed when the button is clicked.
 * @returns {React.Component} Button component.
 */
const Button = ({ primary, children, animated, darkMode, link, onClick }) => {
  let buttonType = styles.secondary;
  
  // Determine button type based on primary and darkMode props
  if (primary) {
    buttonType = darkMode ? styles.primary__dark : styles.primary;
  } else {
    buttonType = darkMode ? styles.secondary__dark : styles.secondary;
  }

  // Concatenate button classes based on buttonType and animated prop
  const buttonClassName = `${styles.button} ${buttonType} ${animated ? 'animated' : ''}`;

  return (
    <Link className={buttonClassName} to={link} onClick={onClick}>
      {children}
    </Link>
  );
};

export default Button;
