import React from 'react';
import './ServiceCard.css';

/**
 * Service card component used in the Services page.
 * @param {Object} props - Component props.
 * @param {string} props.image - Image of the service.
 * @param {string} props.title - Title of the service.
 * @param {string} props.description - Description of the service.
 * @param {Array} props.highlights - Highlights of the service.
 * @returns {React.Component} ServiceCard component.
 */
const ServiceCard = ({ image, title, description, highlights }) => {
    return (
        <div className="service-card animated">
            <img src={image} alt="Service" className="service-card__image" />
            <div className="service-card__text">
                <h3>{title}</h3>
                <p className="service-card__description">{description}</p>
                <ul className="service-card__highlights">
                    {highlights.map((highlight, index) => (
                        <li key={index}>{highlight}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ServiceCard;
