import React from 'react';
import './CallToAction.css';

import Button from '../Button/Button';

/**
 * CallToAction component.
 * @returns {React.Component} CallToAction component.
 */
const CallToAction = () => {
    return (
        <section className="cta">
            <div>
                <h2 className="cta__title animated">Your tech-enabled company automation starts here.</h2>
            </div>
            <Button primary animated link="/contact">
                Get in touch with us
            </Button>
        </section>
    );
};

export default CallToAction;