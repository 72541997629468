import React from 'react';
import './Hero.css';

import Button from '../common/Button/Button';
import videoWebM from '../../assets/videos/particles.webm';
import videoMP4 from '../../assets/videos/particles.mp4';

/**
 * Hero component.
 * @returns {React.Component} Hero component.
 */
const Hero = () => {
    return (
        <section className="hero">
            <video autoPlay playsInline loop muted id='hero__video'>
                <source src={videoWebM} type="video/webm" />
                <source src={videoMP4} type="video/mp4" />
            </video>
            <div className="hero__container animated">
                <div className="hero__content">
                    <h1>Automating digital ecosystems for growth</h1>
                    <p>We leverage cutting-edge technologies and our advisory expertise to automate your tech & digital environment</p>
                    <Button primary link="about-us">
                        Get to know us
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default Hero;
