import React from 'react';
import './PartnersSection.css';

import wecan from '../../assets/images/wecan.svg';
import srTechnics from '../../assets/images/sr-technics.svg';
import tiko from '../../assets/images/tiko.svg';
import ardentis from '../../assets/images/ardentis.svg';
import wise from '../../assets/images/wise.svg';
import emedswiss from '../../assets/images/emedswiss.svg';

/**
 * Partners section component showcasing the partners Clayver has worked with.
 * @returns {React.Component} Partners section component.
 */
const PartnersSection = () => {
    return (
        <section className='partners-section'>
            <h2 className='animated'>Over the 4 last quarters, we have successfully completed over 10 projects in diverse industries. </h2>
            <ul className='partners-section__logos animated'>
                <li><a href="https://wecangroup.ch/"><img src={wecan} alt="Wecan's logo" /></a></li>
                <li><a href="https://www.srtechnics.com/"><img src={srTechnics} alt="SR Technics' logo" /></a></li>
                <li><a href="https://tiko.ch/"><img src={tiko} alt="Tiko's logo" /></a></li>
                <li><a href="https://www.ardentis.ch/"><img src={ardentis} alt="Ardentis' logo" /></a></li>
                <li><a href="https://wise.swiss/"><img src={wise} alt="Wise's logo" /></a></li>
                <li><a href="https://www.emedswiss.ch/"><img src={emedswiss} alt="Emedswiss' logo" /></a></li>
            </ul>
        </section>
    );
};

export default PartnersSection;
