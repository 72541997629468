import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useResetAnimations } from '../../useResetAnimations';
import './Home.css';

import Header from '../../components/common/Header/Header';
import Hero from '../../components/Hero/Hero';
import PageDescription from '../../components/common/PageDescription/PageDescription';
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import PartnersSection from '../../components/PartnersSection/PartnersSection';
import CallToAction from '../../components/common/CallToAction/CallToAction';
import Footer from '../../components/common/Footer/Footer';

import raysImage from '../../assets/images/rays.webp';
import multiY from '../../assets/images/multi-y.svg';
import keyboard from '../../assets/images/keyboard.webp';
import curvedRays from '../../assets/images/curved-rays.webp';
import mesh from '../../assets/images/mesh.webp';

// Get the offset top of an element
function getOffsetTop(element) {
    let offsetTop = 0;
    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
}

/**
 * Home page.
 * @returns {React.Component} Home page.
 */
function Home() {
    // Get the current location
    const location = useLocation();

    // Set up state variables
    const [previousLocation, setPreviousLocation] = useState();
    const [isServicesAtTop, setIsServicesAtTop] = useState(false);

    // Get a reference to the elements and reset animations
    const elementsRef = useResetAnimations();

    // Reference to the previous hash and key values
    const prevHashRef = useRef(location.hash);
    const prevKeyRef = useRef(location.key);

    // Check if the services section is at the top of the viewport
    useEffect(() => {
        const servicesElement = document.getElementById('services');
        const observer = new IntersectionObserver(
            ([entry]) => setIsServicesAtTop(entry.isIntersecting),
            { rootMargin: `0px 0px -${window.innerHeight / 2}px 0px` }
        );

        if (servicesElement) {
            observer.observe(servicesElement);
        }

        return () => {
            if (servicesElement) {
                observer.unobserve(servicesElement);
            }
        };
    }, []);

    // Update the previous location when the location changes
    useEffect(() => {
        setPreviousLocation(location.pathname);
    }, [location.pathname]);

    // Scroll to the services section when the hash changes
    useEffect(() => {
        if (location.hash === "#services") {
            const headerHeight = document.querySelector('.header').offsetHeight;
            const servicesElement = document.getElementById("services");
            const scrollPosition = getOffsetTop(servicesElement) - headerHeight;;

            // Scroll instantly if the location changed, otherwise scroll smoothly
            if (location.hash === prevHashRef.current && location.key === prevKeyRef.current) {
                window.scrollTo({ top: scrollPosition });
            } else {
                window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
            }
        } else {
            // Scroll instantly if the location changed, otherwise scroll smoothly
            if (location.hash === prevHashRef.current && location.key === prevKeyRef.current) {
                window.scrollTo({ top: 0 });
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }

        // Update the previous hash and key values
        prevHashRef.current = location.hash;
        prevKeyRef.current = location.key;
    }, [location.hash, location.key]);

    // Highlight the "Our services" link in the header when the services section is visible
    useEffect(() => {
        const navigationServicesElement = document.getElementById('navigation__services');
        if (navigationServicesElement !== null) {
            navigationServicesElement.style.boxShadow = isServicesAtTop ? '0px -5px 0px var(--color-light-green) inset' : '';
        }
    }, [isServicesAtTop]);

    return (
        <div ref={elementsRef} className="home">
            <Header position={"fixed"} />
            <Hero />
            <section className="intro__section">
                <div className="intro__image">
                    <img src={raysImage} alt="Abstract rays" />
                </div>
                <div className="intro__text">
                    <h2 className='intro__title animated'>Clayver is a technology-driven consulting firm</h2>
                    <p className='animated'>We believe that mastering digital automation and optimization is essential for businesses to thrive in today's competitive landscape.</p>
                    <p className='animated'>Our mission is to help organisations navigate and excel in the tech era, by setting up effective and pragmatic digital solutions.</p>
                </div>
            </section>
            <PageDescription
                title="> Our 3 complementary practices"
                description="From formulating robust digital strategies to executing comprehensive software development, we tailor our services to meet every aspect of your digital transformation journey."
                illustration={multiY}
                id="services"
            />
            <section className='services__section'>
                <ServiceCard image={keyboard} title="Core Ecosystem" description="Assembling your ideal tech environment with:" highlights={['SaaS-based Architectures', 'Robust Cybersecurity', 'Optimised Digital Delivery']} />
                <ServiceCard image={curvedRays} title="Process Optimisation" description="Streamlining digital processes using:" highlights={['Operating model refinements', 'Design thinking', 'AI & Low-Code Automation']} />
                <ServiceCard image={mesh} title="Business Intelligence" description="Unlocking the full potential of your digital environment with:" highlights={['AI-driven decision making', 'Generative AI', 'Financial Process Optimization']} />
            </section>
            <PartnersSection />
            <CallToAction />
            <Footer />
        </div>
    );
}

export default Home;
