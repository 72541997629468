import React from 'react';
import './FounderCard.css';

/**
 * Founder card component used in the About Us page.
 * @param {Object} props - Component props.
 * @param {string} props.image - Image of the founder.
 * @param {string} props.name - Name of the founder.
 * @param {string} props.description - Description of the founder.
 * @param {string} props.link - Link to the founder's LinkedIn profile.
 * @param {string} props.background - Background color of the card.
 *  @param {boolean} props.reverse - Whether the card is reversed or not.
 * @returns {React.Component} FounderCard component.
 */
const FounderCard = ({ image, name, description, link, background, reverse }) => {
    return (
        <div className={`founder-card ${reverse ? 'founder-card--reverse' : ''}`} style={{ background: background }}>
            <div className="founder-card__image animated">
                <img src={image} alt={name} />
            </div>
            <div className="founder-card__text animated">
                <p className="founder-card__title animated">{name}</p>
                <p className="animated">{description}</p>
                <a className="animated" href={link} target="_blank" rel="noopener noreferrer">
                    LinkedIn profile >
                </a>
            </div>
        </div>
    );
};

export default FounderCard;
