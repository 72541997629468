import React from 'react';
import './PageDescription.css';

/**
 * Page description component used at the top of pages to provide an introduction.
 * @param {Object} props - Component props.
 * @param {string} props.title - Title of the page.
 * @param {string} props.description - Description of the page.
 * @param {string} props.illustration - Illustration of the page.
 * @param {string} props.id - ID of the component, used for scrolling.
 * @returns {React.Component} PageDescription component.
 */
const PageDescription = ({ title, description, illustration, id }) => {
    return (
        <section className="page-description" id={id}>
            <div className="page-description__container">
                <div className="page-description__text animated">
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
            <div className="page-description__image">
                <img src={illustration} alt='Abstract shape' />
            </div>
        </section>
    );
};

export default PageDescription;
