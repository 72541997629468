import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

import logo from '../../../assets/images/logo.svg';
import Button from '../Button/Button';

/**
 * Header component.
 * @param {Object} props - Component props.
 * @param {string} props.position - Position of the header.
 * @param {boolean} props.isContact - Whether the header is in the contact page or not.
 * @returns {React.Component} Header component.
 */
const Header = ({ position, isContact }) => {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();

  // Close menu when clicking outside of it
  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    
    setIsOpen(false);
  };

  // Add event listener to the document when the menu is open
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={`header ${isOpen ? 'open' : ''}`} style={{ position: position }} ref={node}>
      <nav className="navigation">
        <button onClick={toggleMenu} className="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className={`navigation__list ${isOpen ? 'open' : ''}`}>
          <li><Link to="/" onClick={toggleMenu}><img src={logo} alt="Clayver Logo" /></Link></li>
          <li className='navigation__link' id='navigation__services'><Link to="/#services" onClick={toggleMenu}>Our services</Link></li>
          <li className='navigation__link' id='navigation__about-us'><Link to="/about-us" onClick={toggleMenu}>About us</Link></li>
        </ul>
      </nav>
      {!isContact && (
        <Button primary={false} link="/contact">
          Contact
        </Button>
      )}
    </header>
  );
};

export default Header;